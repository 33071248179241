import React from "react";
import Navbar from "../components/Navbar/Navbar";
import FullProduct from "../components/FullProducts/FullProducts";
const FullProductRoute = () => {
  return (
    <>
      <Navbar />
      <FullProduct />
    </>
  );
};

export default FullProductRoute;
