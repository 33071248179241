import React, { useCallback, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import LoginForm from "../components/LoginForm";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();

  const getUser = useCallback(async () => {
    let userExist = await localStorage.getItem("BandhejHub");
    if (userExist) {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    getUser();
  }, [getUser]);
  return (
    <>
      <Navbar />
      <LoginForm />
    </>
  );
}

export default Login;
