import React, { useState } from "react";
import styles from "./NewsLetter.module.css";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    // Implement subscription logic here
    console.log("Subscribed with:", email);
    setEmail(""); // Clear input after subscription
  };

  return (
    <div className={styles.newsletterContainer}>
      <div className={styles.newsletterContent}>
        <h2 className={styles.newsletterTitle}>Stay in the Loop</h2>
        <p className={styles.newsletterDescription}>
          Subscribe to our newsletter for exclusive offers and updates.
        </p>
      </div>
      <div className={styles.newsletterForm}>
        <input
          type="email"
          className={styles.newsletterInput}
          placeholder="Enter your email"
          value={email}
          onChange={handleInputChange}
        />
        <button className={styles.newsletterButton} onClick={handleSubscribe}>
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default Newsletter;
