import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { GET_PRODUCT_BY_CATEGORY } from "../Apis";
import axios from "axios";
import styles from "./FullProducts.module.css";
import ProductCard from "../ProductCard/ProductCard";
import ProductDetailModal from "../../Modals/ProductDetails";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const FullProduct = () => {
  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page");
  const [sortOption, setSortOption] = useState("Featured");
  const [FullProductData, setFullProductData] = useState(null);
  if (category) document.title = `${category} - Bandhej Hub`;

  const sortProducts = (products) => {
    const data = products.products;
    switch (sortOption) {
      case "Featured":
        return [...data];
      case "priceAccending":
        return [...data].sort((a, b) => a.showPrice - b.showPrice);
      case "priceDecending":
        return [...data].sort((a, b) => b.showPrice - a.showPrice);
      case "AtoZ":
        return [...data].sort((a, b) => a.name.localeCompare(b.name));
      case "ZtoA":
        return [...data].sort((a, b) => b.name.localeCompare(a.name));
      case "NewToOld":
        return [...data].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "OldToNew":
        return [...data].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      default:
        return data;
    }
  };

  const FetchFullProductList = async () => {
    try {
      const response = await axios.get(
        `${GET_PRODUCT_BY_CATEGORY}/${category}/${currentPage}`
      );

      setFullProductData(response.data);
      // setFullProductData(sortProducts(response.data));
    } catch (error) {
      console.log("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    FetchFullProductList();
  }, [currentPage]);

  const pageNumbers = [];
  for (let i = 1; i <= FullProductData?.totalPages; i++) {
    pageNumbers.push(i);
  }
  const handleSortSelection = (e) => {
    setSortOption(e.target.value);
  };

  // MODAL LOGIN
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  return (
    <>
      <div>
        <div className={styles.fullproductContainer}>
          <div className={styles.productDetailsTopContainerNavigation}>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <MdOutlineKeyboardArrowRight />
            </div>
            <div>
              <Link to={`/${category}`}>{category}</Link>
            </div>
          </div>

          <div className={styles.fullproductHeading} id="pageTop">
            <div>
              <h2>{category}</h2>
            </div>

            <div className={styles.fullproductHeadingFilterIcons}>
              {/* <div>
                <p onClick={() => setShowFilter(!showFilter)}>
                  {" "}
                  <i>
                    <FiFilter />
                  </i>
                  Filter
                </p>
                {showFilter && (
                  <div className="fullproduct-filter-section">
                    <div onClick={() => handleFilterSelection("top-trending")}>
                      Top Trending
                    </div>
                    <div onClick={() => handleFilterSelection("top-trending")}>
                      Best Selling
                    </div>
                    <div onClick={() => handleFilterSelection("top-trending")}>
                      Offered Item
                    </div>
                    <div onClick={() => handleFilterSelection("best-selling")}>
                      Best Selling
                    </div>
                    <div onClick={() => handleFilterSelection("offered-item")}>
                      Offered Item
                    </div>
                    <div onClick={() => handleFilterSelection("offered-item")}>
                      Offered Item
                    </div>
                  </div>
                )}
              </div> */}

              <select name="" id="" onChange={handleSortSelection}>
                <option value="Featured">Featured</option>
                <option value="priceAccending">Price, low to hight</option>
                <option value="priceDecending"> Price, high to low</option>
                <option value="AtoZ">Alphabetically, A-Z</option>
                <option value="ZtoA">Alphabetically, Z-A</option>
                <option value="NewToOld"> Date, new to old</option>
                <option value="OldToNew"> Date, old to new</option>
              </select>
            </div>
          </div>

          <div className={styles.fullproductListContainer}>
            <div className={styles.fullproductList}>
              {FullProductData?.products?.map((product) => (
                <ProductCard
                  key={product.productId}
                  product={product}
                  openModal={openModal}
                />
              ))}
            </div>
          </div>
          {/* paginations  */}
          <div className={styles.fullProductListPages}>
            {/* Prev button */}
            <div className={styles.PrevNextButton}>
              {currentPage > 1 && (
                <Link to={`/${category}?page=${parseInt(currentPage) - 1}`}>
                  Prev
                </Link>
              )}
            </div>

            {/* Page numbers */}
            <div className={styles.pageNumbers}>
              {pageNumbers.map((pageNumber) => (
                <Link key={pageNumber} to={`/${category}?page=${pageNumber}`}>
                  <p
                    className={`${styles.pageLink} ${
                      pageNumber == currentPage ? styles.activePage : ""
                    }`}
                  >
                    {pageNumber}
                  </p>
                </Link>
              ))}
            </div>
            {/* Next button */}
            <div className={styles.PrevNextButton}>
              {currentPage < pageNumbers.length && (
                <Link to={`/${category}?page=${parseInt(currentPage) + 1}`}>
                  Next
                </Link>
              )}
            </div>
          </div>
        </div>

        {isModalOpen && (
          <ProductDetailModal
            isOpen={isModalOpen}
            onClose={closeModal}
            selectedProduct={selectedProduct}
          />
        )}
      </div>
    </>
  );
};

export default FullProduct;
