import React from "react";
import Navbar from "../components/Navbar/Navbar";
import PaymentThankYou from "../components/PaymentThankYou";

const PaymentThankYouPage = () => {
  document.title = "Thank you For Shopping with us - Bandhej Hub";

  return (
    <div>
      <Navbar />
      <PaymentThankYou />
    </div>
  );
};

export default PaymentThankYouPage;
