import React, { useContext, useState } from "react";
import styles from "./Cart.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { VALIDATE_PROMO_CODE } from "../Apis";
import CartPic from "../../assets/cart.png";
import { UserContext } from "../../context/userContext";
import { MdShoppingCartCheckout } from "react-icons/md";
import { toast } from "react-toastify";
import Loader from "../../assets/spinnerwhite.svg";
const Cart = () => {
  const { cartData, removeCartData, setCheckoutData } = useContext(UserContext);
  const navigate = useNavigate();
  const [promoLoader, setPromoLoader] = useState(false);
  // {OPEN PRODUCT }
  const handleOpen = (item) => {
    let itemName = item?.variant?.product?.name.replace(/\s+/g, "-");
    navigate(
      `/${item?.variant?.product?.category}/${itemName}/${item?.variant?.productId}?variant=${item?.variant?.sku}`
    );
  };

  let subtotal = 0;

  if (cartData && cartData.length > 0) {
    subtotal = cartData.reduce((acc, item) => {
      if (item && item.variant.product.showPrice !== undefined) {
        const price = item.variant.product.showPrice || 0;
        return acc + price * item?.quantity;
      }
      return acc;
    }, 0);
  }
  // set promocode
  const [discount, setDiscount] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [navigatePpromoCode, setNavigatePpromoCode] = useState("");
  const submitPromoCode = async (e) => {
    setPromoLoader(true);
    e.preventDefault();
    try {
      const response = await axios.post(VALIDATE_PROMO_CODE, {
        promoCode: promoCode,
        totalAmount: subtotal,
      });
      setNavigatePpromoCode(promoCode);
      toast.success("Promo Code Applied");
      setPromoLoader(false);
      setDiscount(response.data.discount);
    } catch (error) {
      setPromoLoader(false);
      toast.error(error.response.data.message);
    }
  };
  // {CHECKOUT FUNCTION }
  const handleCheckout = () => {
    setCheckoutData(cartData);
    if (navigatePpromoCode === "") {
      navigate("/checkout");
    } else navigate(`/checkout?promocode=${navigatePpromoCode}`);
  };

  return (
    <>
      <div className={styles.cartMainSection}>
        {cartData?.length > 0 ? (
          <>
            <div className={styles.cartSection}>
              <table>
                <thead>
                  <tr>
                    <th>PRODUCT</th>
                    <th>PRICE</th>
                    <th>QUANTITY</th>
                    <th>SUBTOTAL</th>
                  </tr>
                </thead>

                <tbody>
                  {cartData?.map((item) => (
                    <tr className={styles.cartContent} key={item?.cartId}>
                      <td>
                        <div className={styles.cartImagesDetail}>
                          <div>
                            <img
                              style={{ cursor: "pointer" }}
                              src={item?.variant?.image}
                              onClick={() => handleOpen(item)}
                              alt="Loading..."
                            />
                          </div>

                          <div className={styles.cartDetail}>
                            <p>{item?.variant?.product?.name}</p>
                            <p>
                              Color: <b>{item?.variant?.color}</b>
                            </p>

                            <p
                              onClick={() => removeCartData(item?.variant?.sku)}
                            >
                              X
                            </p>
                          </div>
                        </div>
                      </td>

                      <td>
                        <p>
                          Rs.
                          {item?.variant?.product?.showPrice?.toFixed(2) || 0}
                        </p>
                      </td>
                      <td>{item?.quantity}</td>
                      <td>
                        Rs.
                        {(
                          (item?.variant?.product?.showPrice || 0) *
                          (item?.quantity || 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={styles.cartSummary}>
              <div className={styles.cartAmount}>
                <h2>Summary</h2>
                {/* promocode input   */}
                <div className={styles.promoCode}>
                  <form onSubmit={submitPromoCode}>
                    <div>
                      <input
                        type="text"
                        required
                        placeholder="Promo Code"
                        name="promo"
                        onChange={(e) => setPromoCode(e.target.value)}
                      />
                    </div>
                    <div>
                      {promoLoader ? (
                        <button type="submit" disabled>
                          <img src={Loader} alt="" />
                        </button>
                      ) : (
                        <button type="submit">Submit</button>
                      )}
                    </div>
                  </form>
                </div>

                {/* subtotal  */}
                <div className={styles.cartAmountRupee}>
                  <h3>Subtotal</h3>
                  <h3>Rs.{subtotal}</h3>
                </div>
                {/* promocode  */}
                <div
                  className={`${styles.cartAmountRupee} ${styles.highlight}`}
                >
                  <h3>Discount</h3>
                  <h3> -Rs.{discount.toFixed(0)}</h3>
                </div>
                {/* delivery  */}
                <div className={styles.cartAmountRupee}>
                  <h3>Delivery</h3>
                  <i className={styles.freeDeliveryTag}>Free Delivery</i>
                  {/* <h3>Rs.0</h3> */}
                </div>
                {/* total  */}
                <div className={styles.cartAmountRupee}>
                  <h3>Total</h3>
                  <h3>Rs.{Math.round(Number(subtotal) - discount)}</h3>
                </div>

                <div className={styles.cartAmountButtons}>
                  <button onClick={handleCheckout}>
                    Checkout <MdShoppingCartCheckout />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.cartDataNotFound}>
            <div>
              <img src={CartPic} alt="" />
            </div>
            <div>
              <h3 className="cart-data-not-available">Your cart is empty</h3>
              <p>
                You have no items in your shopping cart <br /> Let's go buy
                something
              </p>
            </div>
            <div>
              <Link to="/">
                <button>Shop Now</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
