import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Link } from "react-router-dom";
import styles from "./Products.module.css";
import ProductCard from "../ProductCard/ProductCard";
import { IoIosArrowRoundForward } from "../../assets/Icons";

const Product = ({ heading, allProductsData, summary, query }) => {
  return (
    <div className={styles.allproductContainer}>
      <div>
        <h2 className={styles.heading}>{heading}</h2>
        <p className={styles.summary}>{summary}</p>
      </div>

      <div className={styles.productListContainer}>
        <div className={styles.productList}>
          {/* Check if loading or data is present */}
          {!allProductsData
            ? Array(4)
                .fill()
                .map((_, index) => (
                  <div key={index} className={styles.productSkeletonLoading}>
                    {/* Skeleton Image */}
                    <Skeleton height={150} />
                    {/* Skeleton Category */}
                    <Skeleton width={140} style={{ marginTop: "14px" }} />
                    {/* Skeleton Title */}
                    <Skeleton width={150} style={{ marginTop: "8px" }} />
                    {/* Skeleton Price */}
                    <Skeleton width={120} style={{ marginTop: "8px" }} />
                    {/* Skeleton Buttons */}
                    <div className={styles.skeletonLoadingButton}>
                      <Skeleton width={40} height={35} />
                      <Skeleton width={40} height={35} />
                    </div>
                  </div>
                ))
            : allProductsData
                ?.slice(0, 4)
                .map((product) => (
                  <ProductCard key={product?.productId} product={product} />
                ))}
        </div>
      </div>

      <Link to={`${query}?page=1`}>
        <button className={styles.viewBtn}>
          <div>
            View All <IoIosArrowRoundForward />
          </div>
        </button>
      </Link>
    </div>
  );
};

export default Product;
