import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import styles from "./WhatsappButton.module.css";

const WhatsappButton = () => {
  const [hovered, setHovered] = useState(false);

  return (
    <div>
      <a href="https://wa.me/7740930250" target="_blank" rel="noreferrer">
        <div
          className={`${styles.whatsappButton} ${
            hovered ? styles.hovered : ""
          }`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <i>
            <FaWhatsapp />
          </i>
          {hovered && <p>Chat now</p>}
        </div>
      </a>
    </div>
  );
};

export default WhatsappButton;
