import React, { useCallback, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import SignUpForm from "../components/SignUpForm";
import { useNavigate } from "react-router-dom";
function SignUp() {
  const navigate = useNavigate();

  const getUser = useCallback(async () => {
    let userExist = await localStorage.getItem("BandhejHub");
    if (userExist) {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    getUser();
  }, [getUser]);
  return (
    <div>
      <Navbar />
      <SignUpForm />
    </div>
  );
}

export default SignUp;
