import React from "react";
import styles from "./Collections.module.css";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

const Collections = () => {
  const navigate = useNavigate();
  const collections = [
    {
      image:
        "https://wholetex.sgp1.cdn.digitaloceanspaces.com/full/m-n-kacchi-bandhej-satin-bandhani-saree-922.jpg",
      category: "Saree",
    },
    {
      image:
        "https://5.imimg.com/data5/YO/BQ/FD/ANDROID-49644284/product-jpeg-500x500.jpg",
      category: "Dupatta",
    },
    {
      image:
        "https://kalabandhej.com/wp-content/uploads/2024/04/Pure-Gaji-Silk-Bandhani-Dress-Gharchola-2.jpg",
      category: "Dress",
    },
    {
      image:
        "https://www.aishwaryadesignstudio.com/content/images/thumbs/0136900_traditional-dark-green-bandhani-designer-lehenga-choli-for-wedding.jpeg",
      category: "Lehangas",
    },
  ];
  return (
    <div className={styles.ourCollections}>
      <h1>Explore Our Collections</h1>
      <div className={styles.collections}>
        {collections.map((collection, index) => (
          <div key={index} className={styles.collection}>
            <img src={collection.image} alt="" />

            <button onClick={() => navigate(`/${collection.category}?page=1`)}>
              {collection.category}

              <HiArrowNarrowRight />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collections;
