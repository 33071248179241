import React from "react";
import styles from "./ServiceHome.module.css";
import {
  LiaShippingFastSolid,
  IoMdHeartEmpty,
  HiOutlineArrowPathRoundedSquare,
} from "../../assets/Icons";
import { RiSecurePaymentFill } from "react-icons/ri";

const ServiceHome = () => {
  return (
    <div className={styles.serviceMain}>
      <h1>Why Choose Bandhej Hub</h1>
      <div className={styles.serviceSectionHome}>
        <div className={styles.serviceSections}>
          <div>
            <i>
              <LiaShippingFastSolid />
            </i>
          </div>
          <div>
            <h3>Free Shipping</h3>
          </div>
          <div>
            <p>Free shipping on Orders above ₹999</p>
          </div>
        </div>
        <div className={styles.serviceSections}>
          <div>
            <i>
              <HiOutlineArrowPathRoundedSquare />
            </i>
          </div>
          <div>
            <h3>Easy Return Policy</h3>
          </div>
          <div>
            <p>Simple return and refund policy</p>
          </div>
        </div>
        <div className={styles.serviceSections}>
          <div>
            <i>
              <RiSecurePaymentFill />
            </i>
          </div>
          <div>
            <h3>Secure Payments</h3>
          </div>
          <div>
            <p>Multiple payment options</p>
          </div>
        </div>
        <div className={styles.serviceSections}>
          <div>
            <i style={{ color: "white" }}>
              <IoMdHeartEmpty style={{ color: "white" }} />
            </i>
          </div>
          <div>
            <h3>Proudly Made In INDIA</h3>
          </div>
          <div>
            <p>Crafted by local artisans and in-house karigars</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceHome;
