import React, { useContext, useEffect, useState } from "react";
import styles from "./ProductDetail.module.css";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import {
  RiFacebookFill,
  TfiHeart,
  FaTwitter,
  FaWhatsapp,
  PiShoppingCart,
  MdVerified,
} from "../../assets/Icons";
import axios from "axios";
import Loader from "../../assets/spinnerwhite.svg";
import { UserContext } from "../../context/userContext";
import { GET_PRODUCT_BY_ID } from "../Apis";
import LoginModal from "../../Modals/Login";
import RegisterModal from "../../Modals/Register";

const ProductDetail = () => {
  const { handleAddToCart, cartLoader, isLoginOpen, isRegisterOpen } =
    useContext(UserContext);

  const navigate = useNavigate();
  //  ITEM ID AND SKU VARIANT
  const { category, name, productId } = useParams();

  const [searchParams] = useSearchParams();
  const sku = searchParams.get("variant");
  // STATE WHICH HAVE WHOLE PRODUCT INFO
  const [productInfo, setProductInfo] = useState(null);
  if (productInfo) document.title = `${productInfo?.name} - Bandhej Hub`;

  // QUANTITY
  const [quantity, setQuantity] = useState(1);
  // GET PRODUCT BY ID
  useEffect(() => {
    const getProductById = async () => {
      try {
        const response = await axios.get(`${GET_PRODUCT_BY_ID}/${productId}`);
        setProductInfo(response.data.product);
      } catch (error) {
        console.log(error);
      }
    };
    getProductById();
  }, [productId]);

  // STATE OF STORE INDIVIDUAL PRODUCTINFO
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    const match = productInfo?.variants?.find((variant) => variant.sku === sku);
    setSelectedImage(match);
  }, [sku, productInfo]);

  // QUANTITY SET UP
  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    setQuantity(value);
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  //this state have {sku,image,color}
  const handleShowImg = (variant) => {
    navigate(
      `/${productInfo?.category}/${productInfo?.name.replace(/\s+/g, "-")}/${
        productInfo?._id
      }?variant=${variant?.sku}`
    );
  };

  //navigation options for product review,description,detail
  const [option, setOption] = useState("productDetails");
  const handleOption = (option) => {
    setOption(option);
  };

  const UniqueNumber = (str) => {
    if (str) {
      let result = str.substring(4, 9);
      return result;
    }
  };
  const truncateText = (text) => {
    if (text) {
      if (text.length < 22) return text;
      return text.slice(0, 22) + "...";
    }
  };

  return (
    <>
      <div className={styles.productDetailsTopContainer}>
        {/* navigation link  */}
        <div className={styles.productDetailsTopContainerNavigation}>
          <div>
            <Link to="/">Home</Link>
          </div>
          <div>
            <MdOutlineKeyboardArrowRight />
          </div>
          <div>
            <Link to={`/${productInfo?.category}`}>
              {productInfo?.category}
            </Link>
          </div>
          <div>
            <MdOutlineKeyboardArrowRight />
          </div>
          <div>{truncateText(productInfo?.name)}</div>
        </div>

        <div className={styles.productDetailsContainerr}>
          <div className={styles.productImageContainerr}>
            <div className={styles.productImageContainerrMoreColors}>
              {productInfo?.variants?.map((varient, index) => (
                <div key={varient?.sku} onClick={() => handleShowImg(varient)}>
                  <img
                    className={
                      varient?.image === selectedImage?.image
                        ? styles.activeImg
                        : ""
                    }
                    src={varient?.image}
                    alt={productInfo?.name}
                  />
                </div>
              ))}
            </div>

            <div className={styles.productImageContainerrTopShowImg}>
              <img
                src={selectedImage?.image}
                alt={productInfo?.name}
                className="product-imagee"
              />
            </div>
          </div>

          <div className={styles.productDetailss}>
            <div>
              {/* product heading   */}
              <h2>{productInfo?.name}</h2>
              {/* stock and sku  */}
              <div className={styles.productStock}>
                <div>
                  {selectedImage?.stock ? (
                    <p className={styles.productDetailsInStock}>In Stock</p>
                  ) : (
                    <p className={styles.productDetailsOutStock}>
                      Out of stock
                    </p>
                  )}
                </div>
                <div>SKU : {UniqueNumber(selectedImage?.sku)}</div>
              </div>
              {/* price  */}
              <p className={styles.productDetailsPrice}>
                Rs.{productInfo?.showPrice}.00{" "}
                {productInfo?.showPrice !== productInfo?.price && (
                  <span>Rs.{productInfo?.price}.00</span>
                )}
                <i>(Including all taxes)</i>
              </p>
              {/* color  */}
              <p className={styles.productColor}>
                Color - {selectedImage?.color}
              </p>
              {/* quantity and cart button  */}
              <div className={styles.quantityContainer}>
                <div className={styles.quantityControl}>
                  <button onClick={decreaseQuantity}>-</button>
                  <input
                    type="number"
                    min="1"
                    // disabled
                    readOnly
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  <button onClick={increaseQuantity}>+</button>
                </div>
                {cartLoader ? (
                  <button className={styles.purchaseButtonLoader} disabled>
                    {/* Loading... */}
                    <img src={Loader} alt="" />
                  </button>
                ) : (
                  <button
                    className={styles.purchaseButton}
                    onClick={() =>
                      handleAddToCart(quantity, selectedImage?.sku)
                    }
                  >
                    <div>Add Cart</div>
                    <div>
                      <PiShoppingCart />
                    </div>
                  </button>
                )}
              </div>
              {/* whatsapp button  */}
              <div className={styles.whatsappOrderButton}>
                <a
                  href={`https://wa.me/7740930250?text=https://www.bandhejhub.tech/${category}/${name}/${productId}?variant=${sku}%0A%0AI%20want%20to%20buy%20it😍`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>Order via Whatsapp</i>
                  <FaWhatsapp />
                </a>
              </div>
              {/* whishlist  */}
              <div className={styles.addToWhitlistShare}>
                <div className={styles.addIconsDetaiils}>
                  <div>
                    <TfiHeart />
                  </div>
                  <div>Add to Wishlist</div>
                </div>
                <div>
                  <div className={styles.shareIcons}>
                    <div>
                      <i>share :</i>
                    </div>
                    <div>
                      <RiFacebookFill />
                    </div>
                    <div>
                      <FaTwitter />
                    </div>
                    <div>
                      <FaWhatsapp />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.singleProductHashline}></div>
              <div className={styles.singleProductOptions}>
                <p
                  className={option === "productDetails" ? styles.active : ""}
                  onClick={() => handleOption("productDetails")}
                >
                  Product Details
                </p>
                <p
                  className={option === "description" ? styles.active : ""}
                  onClick={() => handleOption("description")}
                >
                  Description
                </p>
                <p
                  className={option === "reviews" ? styles.active : ""}
                  onClick={() => handleOption("reviews")}
                >
                  Reviews
                </p>
                <p
                  className={option === "returnPolicy" ? styles.active : ""}
                  onClick={() => handleOption("returnPolicy")}
                >
                  {" "}
                  Return Policy
                </p>
              </div>

              {option === "productDetails" && (
                <>
                  {/* fabric  */}
                  <div>
                    <strong
                      style={{
                        textTransform: "uppercase",
                        fontSize: "14px",
                      }}
                    >
                      fabric
                    </strong>{" "}
                    : <span>{productInfo?.fabric}</span>
                  </div>
                  {/* pattern  */}
                  {productInfo?.pattern && (
                    <div>
                      <strong
                        style={{
                          textTransform: "uppercase",
                          fontSize: "14px",
                        }}
                      >
                        pattern
                      </strong>{" "}
                      : <span>{productInfo?.pattern}</span>
                    </div>
                  )}
                  {/* dimensions  */}
                  <div>
                    <strong
                      style={{
                        textTransform: "uppercase",
                        fontSize: "14px",
                      }}
                    >
                      dimension
                    </strong>{" "}
                    : <span>{productInfo?.dimension}</span>
                  </div>
                  {/* blouse  */}
                  {productInfo?.blouse && (
                    <div>
                      <strong
                        style={{
                          textTransform: "uppercase",
                          fontSize: "14px",
                        }}
                      >
                        blouse
                      </strong>{" "}
                      : <span>{productInfo?.blouse}</span>
                    </div>
                  )}
                  {/* weight */}
                  <div>
                    <strong
                      style={{
                        textTransform: "uppercase",
                        fontSize: "14px",
                      }}
                    >
                      weight
                    </strong>{" "}
                    : <span>{productInfo?.weight}</span>
                  </div>
                  {/* size  */}
                  <div>
                    <strong
                      style={{
                        textTransform: "uppercase",
                        fontSize: "14px",
                      }}
                    >
                      size
                    </strong>{" "}
                    : <span>{productInfo?.size}</span>
                  </div>
                  {/* careguide  */}
                  {productInfo?.careGuide && (
                    <div>
                      <strong
                        style={{
                          textTransform: "uppercase",
                          fontSize: "14px",
                        }}
                      >
                        careguide
                      </strong>{" "}
                      : <span>{productInfo?.careGuide}</span>
                    </div>
                  )}
                </>
              )}
              {option === "description" && (
                <>
                  {productInfo?.description && (
                    <p className={styles.productDetailsDesc}>
                      {productInfo?.description}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className={styles.sellerInfo}>
              <i>
                Sold By : {productInfo?.seller?.shopName}
                <span>
                  <MdVerified />{" "}
                </span>
              </i>
            </div>
          </div>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} />
      <RegisterModal isOpen={isRegisterOpen} />
    </>
  );
};

export default ProductDetail;
