import React, { useEffect, useState } from "react";
import TopHeader from "../components/TopHeader/TopHeader";
import Navbar from "../components/Navbar/Navbar";
import Product from "../components/Products/Products";
import axios from "axios";
import { GET_PRODUCT_BY_CATEGORY } from "../components/Apis";
import WhatsappButton from "../components/WhatsappButton/WhatsappButton";
import ServiceHome from "../components/ServiceHome/ServiceHome";
import Collections from "../components/OurCollections/Collections";
import Newsletter from "../components/NewsLetter/NewsLetter";
import CustomerReview from "../components/CustomerReview/CustomerReview";
import HeaderBanner from "../components/HeaderBanner/HeaderBanner";
import MiddleBanner from "../assets/MiddleBanner.svg";
const Home = () => {
  document.title = "Bandhej Hub - Home ";
  const [sareeData, setSareeData] = useState(null);
  useEffect(() => {
    const getProductBySaree = async () => {
      try {
        const response = await axios.get(`${GET_PRODUCT_BY_CATEGORY}/Saree/1`);
        setSareeData(response.data.products.reverse());
      } catch (error) {
        console.log("saree data error", error);
      }
    };
    getProductBySaree();
  }, []);
  const [dupattaData, setDupattaData] = useState(null);
  useEffect(() => {
    const getProductByDupatta = async () => {
      try {
        const response = await axios.get(
          `${GET_PRODUCT_BY_CATEGORY}/Dupatta/1`
        );
        setDupattaData(response.data.products.reverse());
      } catch (error) {
        console.log("dupatta data error", error);
      }
    };
    getProductByDupatta();
  }, []);
  const [dressData, setDressData] = useState(null);
  useEffect(() => {
    const getProductByDress = async () => {
      try {
        const response = await axios.get(`${GET_PRODUCT_BY_CATEGORY}/Dress/1`);
        setDressData(response.data.products.reverse());
      } catch (error) {
        console.log("dress data error", error);
      }
    };
    getProductByDress();
  }, []);
  const [lehangaData, setLehangaData] = useState(null);
  useEffect(() => {
    const getProductByLehanga = async () => {
      try {
        const response = await axios.get(
          `${GET_PRODUCT_BY_CATEGORY}/Lehanga/1`
        );
        setLehangaData(response.data.products.reverse());
      } catch (error) {
        console.log("Lehanga data error", error);
      }
    };
    getProductByLehanga();
  }, []);
  return (
    <>
      <TopHeader />
      <Navbar />
      <HeaderBanner />
      <Collections />
      {sareeData?.length !== 0 && (
        <Product
          heading="Bandhani Saree"
          summary="Collections Of Bandhani Sarees"
          allProductsData={sareeData}
          query="Saree"
        />
      )}
      {dupattaData?.length !== 0 && (
        <Product
          heading="Bandhani Dupatta"
          summary="Collections Of Bandhani Dupattas"
          allProductsData={dupattaData}
          query="Dupatta"
        />
      )}
      <img src={MiddleBanner} alt="loading..." />
      {dressData?.length !== 0 && (
        <Product
          heading="Bandhani Dress"
          summary="Collections Of Bandhani Dresss"
          allProductsData={dressData}
          query="Dress"
        />
      )}
      {lehangaData?.length !== 0 && (
        <Product
          heading="Bandhani Lehanga"
          summary="Collections Of Bandhani Lehangas"
          allProductsData={lehangaData}
          query="Lehanga"
        />
      )}
      <Newsletter />
      <ServiceHome />
      <CustomerReview />
      <WhatsappButton />
    </>
  );
};

export default Home;
