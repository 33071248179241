import React from "react";
import Navbar from "../components/Navbar/Navbar";
import CartSection from "../components/Cart/Cart";

const Cart = () => {
  document.title = "Cart - Bandhej Hub";

  return (
    <div>
      <Navbar />
      <CartSection />
    </div>
  );
};

export default Cart;
