import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { FaBox, FaClipboardCheck } from "react-icons/fa";
import { GiConfirmed } from "react-icons/gi";
import { MdOutlineLocalShipping } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import styles from "./OrderDetail.module.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { GET_ORDER_BY_ID, VALIDATE_PROMO_CODE } from "../Apis";
import ScreenLoader from "../../assets/spinnerbandhej.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import TrackingNotAvailableModal from "./TrackingNotModal";
import TrackingAvlModal from "./TrackingAvlModal";

const OrderDetails = () => {
  let accessToken = localStorage.getItem("BandhejHub");
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [showTrackingModal, setShowTrackingModal] = useState(false);

  console.log(order);

  // get order by id
  useEffect(() => {
    const getOrderById = async () => {
      try {
        const response = await axios.get(`${GET_ORDER_BY_ID}/${orderId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });
        setOrder(response.data.orders);
      } catch (error) {
        console.log("error", error);
      }
    };
    getOrderById();
  }, []);
  const truncateText = (text, maxWords) => {
    if (text.lenth < maxWords) return text;
    return text.slice(0, maxWords) + "...";
  };
  const truncateOrderId = (orderId) => {
    if (orderId) {
      const n = orderId.length;
      return orderId.slice(n - 8, n);
    }
  };
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Set to IST (Indian Standard Time)
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    { label: "Placed", icon: <FaBox /> },
    { label: "Confirm", icon: <GiConfirmed /> },
    { label: "Dispatched", icon: <MdOutlineLocalShipping /> },
    { label: "Delivered", icon: <FaClipboardCheck /> },
  ];

  useEffect(() => {
    if (order?.status === "Placed") setCurrentStep(0);
    else if (order?.status === "Confirm") setCurrentStep(1);
    else if (order?.status === "Dispatched") setCurrentStep(2);
    else setCurrentStep(3);
  }, [currentStep, order?.status]);
  useEffect(() => {
    if (order?.order?.promoCode) {
      submitPromoCode();
    }
  }, [order?.order?.promoCode]);

  const cancelOrder = () => {};
  const submitPromoCode = async () => {
    try {
      const response = await axios.post(VALIDATE_PROMO_CODE, {
        promoCode: order?.order?.promoCode,
        totalAmount: order?.variant?.product?.showPrice * order?.quantity,
      });
      setDiscount(response.data.discount);
    } catch (error) {}
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleTrackingModal = () => {
    setShowTrackingModal(true);
  };
  if (!order) {
    return (
      <div className="screenLoader">
        <div>
          <img src={ScreenLoader} alt="loader..." />
        </div>
        <div>
          <span>Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.OrderDetails}>
      {/* top bar for order and buttons  */}
      <div className={styles.orderDetailTop}>
        {/* left order id  */}
        <div className={styles.orderDetailsTopFirst}>
          <div>
            <Link to="/myorders?status=All">
              <FiArrowLeft />
            </Link>
          </div>
          <div>
            <div>Order #{truncateOrderId(order?.orderItemId)}</div>
            <div> Placed on : {formatDate(order?.order.createdAt)}</div>
          </div>
        </div>
        {/* right button  */}
        <div className={styles.orderDetailsTopSecond}>
          <div>
            <button className={styles.getInvoice}>Get Invoice</button>
          </div>
          <div>
            <button onClick={toggleDropdown} className={styles.threeDotsButton}>
              <BsThreeDotsVertical />
            </button>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                <button
                  className={styles.dropdownItem}
                  onClick={handleTrackingModal}
                >
                  Track Order
                </button>
                <button className={styles.dropdownItem} onClick={cancelOrder}>
                  Cancel Order
                </button>
                <button className={styles.dropdownItem}>Contact Support</button>
                {order.trackingId ? (
                  <TrackingAvlModal
                    showTrackingModal={showTrackingModal}
                    setShowTrackingModal={setShowTrackingModal}
                    trackingId={order.trackingId}
                    trackingSite={order.trackingSite}
                  />
                ) : (
                  <TrackingNotAvailableModal
                    showTrackingModal={showTrackingModal}
                    setShowTrackingModal={setShowTrackingModal}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* //prgress  */}
      <div className={styles.orderProgress}>
        <div className={styles.progressTracker}>
          <div className={styles.progressBarEstimate}>
            <div className={styles.steps}>
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`${styles.step} ${
                    currentStep >= index ? styles.active : ""
                  }`}
                >
                  <div className={styles.icon}>{step.icon}</div>
                  <p className={styles.label}>{step.label}</p>
                </div>
              ))}
            </div>
            {/* <div className={styles.estimateDelivery}>
              Estimated Delivery: 23 October 2024
            </div> */}
          </div>

          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{
                width: `${
                  currentStep === 0
                    ? 25
                    : currentStep === 1
                    ? 50
                    : currentStep === 2
                    ? 75
                    : 100
                }%`,
              }}
            ></div>
          </div>

          <div className={styles.info}>
            Haven't received your delivery?{" "}
            <span className={styles.link}>Let us know</span>
          </div>
        </div>
      </div>

      <div className={styles.orderDetailsSecond}>
        {/* order details  */}

        <div className={styles.orderDetailSection}>
          <h3>Order Details</h3>
          <div className={styles.orderDetailsSecondFirst}>
            <div className={styles.orderDetailsMyorder}>
              {/* contact info  */}
              <div className={styles.orderDetailsContact}>
                <p>Contact Information</p>
                <p>+91-{order?.order?.shippingDetail?.phone}</p>
                <p>{order?.order?.shippingDetail?.email}</p>
              </div>
              {/* Name  */}

              {/* Shipping adredd  */}
              <div className={styles.orderDetailsContact}>
                <p>Shipping Address</p>
                <p>{order?.order?.shippingDetail.name}</p>
                <p>{order?.order?.shippingDetail.address}</p>
                <p>
                  {order?.order?.shippingDetail.city} -{" "}
                  {order?.order?.shippingDetail?.pinCode}
                </p>
                <p>{order?.order?.shippingDetail.state} - India</p>
              </div>
            </div>

            <div className={styles.orderDetailsMyorder}>
              {/* contact info  */}
              <div className={styles.orderDetailsContact}>
                <p>Payment Information</p>
                <p>VISA ending with 1232</p>
              </div>

              {/* billing address  */}
              <div className={styles.orderDetailsContact}>
                <p>Billing Address</p>
                {order?.order?.billingDetail.address === "" ? (
                  <>
                    <p>{order?.order?.shippingDetail.name}</p>
                    <p>{order?.order?.shippingDetail.address}</p>
                    <p>
                      {order?.order?.shippingDetail.city} -{" "}
                      {order?.order?.shippingDetail?.pinCode}
                    </p>
                    <p>{order?.order?.shippingDetail.state} - India</p>
                  </>
                ) : (
                  <>
                    <p>{order?.order?.billingDetail.name}</p>
                    <p>{order?.order?.billingDetail.address}</p>
                    <p>
                      {order?.order?.billingDetail.city} -{" "}
                      {order?.order?.billingDetail?.pinCode}
                    </p>
                    <p>{order?.order?.billingDetail.state} - India</p>
                  </>
                )}
              </div>

              <div className={styles.orderDetailsContact}>
                <p>Seller</p>
                <p>{order?.seller.shopName}</p>
              </div>
            </div>
          </div>
        </div>

        {/* order summary  */}
        <div className={styles.orderDetailsSecondSecond}>
          <h3>Order Summary</h3>
          <div className={styles.checkoutCartSection}>
            <div>
              <table>
                <tbody>
                  <tr className={styles.checkoutCartContent}>
                    <td>
                      <div className={styles.checkoutCartImagesDetail}>
                        <div>
                          <img src={order?.variant?.image} alt="Loading..." />
                          <span>{order?.quantity}</span>
                        </div>
                        <div className={styles.checkoutCartDetail}>
                          <p>{order?.variant?.product?.name}</p>
                          <p>Color : {order?.variant?.color}</p>
                          <p>₹{order?.variant?.product?.showPrice}.00</p>
                        </div>
                      </div>
                    </td>

                    <td>
                      ₹{order?.variant?.product?.showPrice * order?.quantity}.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            {/* amount  */}
            <div className={styles.cartAmount}>
              <div className={styles.cartAmountRupee}>
                <h3>Subtotal</h3>
                <h3>
                  Rs.{order?.variant?.product?.showPrice * order?.quantity}.00
                </h3>
              </div>
              {/* promocodsubtotale  */}
              <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
                <h3>Discount</h3>
                <h3>-Rs.{discount.toFixed(0)}.00</h3>
              </div>
              {/* delivery  */}
              <div className={styles.cartAmountRupee}>
                <h3>Delivery</h3>
                <h3>Rs.0</h3>
              </div>
              {/* total  */}
              <div className={styles.cartAmountRupee}>
                <h3>Total</h3>
                <h3>
                  Rs.
                  {(
                    order?.variant?.product?.showPrice * order?.quantity -
                    discount
                  ).toFixed(0)}
                  .00
                </h3>

                {/* <h3>Rs.{Math.round(prices?.total)}</h3> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
