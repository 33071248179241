import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import ProductDetail from "../components/ProductDetail/ProductDetail";
import Product from "../components/Products/Products";
import axios from "axios";
import { GET_PRODUCT_BY_TAG } from "../components/Apis";
function BuyPage() {
  // const [recommended, setRecommended] = useState(null);
  // useEffect(() => {
  //   const getProductByTag = async () => {
  //     try {
  //       const requestBody = {
  //         tag: "Recomanded",
  //       };
  //       const response = await axios.post(GET_PRODUCT_BY_TAG, requestBody);
  //       setRecommended(response.data);
  //     } catch (error) {
  //       console.log("setRecomonded data error", error);
  //     }
  //   };
  //   getProductByTag();
  // }, []);
  return (
    <>
      <Navbar />
      <ProductDetail />
      {/* <Product
        heading="Recomonded Products"
        summary="Collections Of Recommended Items"
        allProductsData={recommended}
        query="Recomanded"
      /> */}
    </>
  );
}

export default BuyPage;
