import backend_ref from "./Backend_ref";
export const AUTH_TOKEN = `${backend_ref}/auth/token`;
//Admin
export const ADD_PRODUCT = `${backend_ref}/admin/addproduct`;
//User
export const GET_USER_PROFILE = `${backend_ref}/user/profile`;
export const USER_LOGOUT = `${backend_ref}/user/logout`;
export const USER_REGISTER = `${backend_ref}/user/register`;
export const USER_LOGIN = `${backend_ref}/user/login`;
export const USER_CHECK_EMAIL = `${backend_ref}/user/checkemail/forgotpassword`;
export const USER_FORGOTPASSWORD = `${backend_ref}/user/forgotpassword`;
export const USER_GOOGLE = `${backend_ref}/auth/google`;
export const USER_GOOGLE_SUCCESS = `${backend_ref}/auth/login/success`;

// CART
export const ADD_TO_CART = `${backend_ref}/cart`;
export const GET_CART_DATA = `${backend_ref}/cart`;
export const REMOVE_CART_DATA = `${backend_ref}/cart`;

//Product
export const GET_PRODUCT_BY_ID = `${backend_ref}/product`;
export const GET_PRODUCT_BY_TAG = `${backend_ref}/product/getproduct/tag`;
export const GET_PRODUCT_BY_CATEGORY = `${backend_ref}/product`;
export const PRODUCT_PRICE = `${backend_ref}/product/getproduct/price`;

// PAYMENT
export const PAYMENT = `${backend_ref}/payment`;
export const PAYMENTVERIFY = `${backend_ref}/payment/verification`;

//ORDER
export const GET_ORDER = `${backend_ref}/order`;
export const GET_ORDER_BY_ID = `${backend_ref}/order/getOrder`;
//Promo Code
export const VALIDATE_PROMO_CODE = `${backend_ref}/promocode/validate`;
