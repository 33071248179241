import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Orders from "../components/Orders/Orders";

const MyOrderPage = () => {
  document.title = "Orders - Bandhej Hub";

  return (
    <div>
      <Navbar />
      <Orders />
    </div>
  );
};

export default MyOrderPage;
