import React from "react";
import Navbar from "../components/Navbar/Navbar";
import OrderDetails from "../components/OrderDetail/OrderDetail";
const OrderDetailsPage = () => {
  document.title = "Order - Bandhej Hub";

  return (
    <div>
      <Navbar />
      <OrderDetails />
    </div>
  );
};

export default OrderDetailsPage;
