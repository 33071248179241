import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { GET_USER_PROFILE } from "../Apis";
import styles from "./Profile.module.css";
import axios from "axios";
import ScreenLoader from "../../assets/spinnerbandhej.svg";
const Profile = () => {
  let { handleSignOut, isTokenExpired, refreshAccessToken, accessToken } =
    useContext(UserContext);
  const [profile, setProfile] = useState(null);

  const fetchUserData = async () => {
    if (isTokenExpired(accessToken)) accessToken = await refreshAccessToken();
    try {
      const response = await axios.get(GET_USER_PROFILE, {
        headers: { Authorization: `Bearer ${accessToken}` },
        withCredentials: true,
      });
      setProfile(response.data.user);
    } catch (error) {
      console.error("Error fetching protected data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  if (!profile) {
    return (
      <div className="screenLoader">
        <div>
          <img src={ScreenLoader} alt="loader..." srcset="" />
        </div>
        <div>
          <span>Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={styles.accountInfo}>
        <ul>
          <li>
            <b>Account Information</b>
          </li>
          <li>
            <b>Name</b> {profile?.name}
          </li>
          <li>
            <b>Email</b> {profile?.email}
          </li>
          <li>
            <b>Phone</b> {profile?.phone}
          </li>

          <button onClick={handleSignOut}>Log Out</button>
        </ul>
      </div>
    </div>
  );
};

export default Profile;
