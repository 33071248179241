import React from "react";
import styles from "./TrackingNotModal.module.css";

const TrackingNotAvailableModal = ({
  showTrackingModal,
  setShowTrackingModal,
}) => {
  if (showTrackingModal === false) return null; // Don't render if not visible

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={() => setShowTrackingModal(false)}
        >
          &times;
        </button>
        <h2>Track Your Order</h2>
        <div className={styles.modalIcon}>
          <span>⚠️</span>
        </div>
        <h3>Tracking Not Available</h3>
        <p>
          We're sorry, but tracking information is not available for this order
          at the moment.
        </p>
      </div>
    </div>
  );
};

export default TrackingNotAvailableModal;
