import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProductCard.module.css";
import Loader from "../../assets/spinnerbandhej.svg";
import { UserContext } from "../../context/userContext";
import { PiShoppingCart, TfiHeart } from "../../assets/Icons";
const ProductCard = ({ product }) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const { handleAddToCart } = useContext(UserContext);

  // {FETCH CONVERTED PRICE}

  const handleNavigate = () => {
    navigate(
      `/${product?.category}/${product?.name.replace(/\s+/g, "-")}/${
        product?.productId
      }?variant=${product?.variants[0]?.sku}`
    );
  };
  const truncateText = (text) => {
    if (text.length < 22) return text;
    return text.slice(0, 22) + "...";
  };
  return (
    <>
      <div className={styles.productCard}>
        {/* {Discount tag } */}
        {product?.discount && (
          <p className={styles.productCardTag}>Save {product?.discount}%</p>
        )}
        {/* Image  */}
        <div className={styles.productImageContainer} onClick={handleNavigate}>
          <img src={product?.variants[0]?.image} alt={product?.name} />
        </div>
        <div className={styles.productInfo}>
          <div>
            <span>{product?.category}</span>
            <p>{truncateText(product?.name)}</p>
          </div>

          <div className={styles.productInfoPriceCart}>
            <div>
              <p>Rs.{product?.showPrice}.00</p>
              {/* <p>{convertedPrice}</p> */}
            </div>

            <div className={styles.productInfoPriceCartBtn}>
              <div>
                {buttonLoader ? (
                  <img
                    src={Loader}
                    alt="Loading..."
                    className={styles.loader}
                  />
                ) : (
                  <button
                    onClick={() => handleAddToCart(1, product?.variants[0].sku)}
                  >
                    <PiShoppingCart />
                    {/* Add Cart */}
                  </button>
                )}
              </div>
              <div>
                <button>
                  <TfiHeart />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
