import React from "react";
import Banner from "../../assets/bhPosterHeader.jpg";
import styles from "./HeaderBanner.module.css";
const HeaderBanner = () => {
  return (
    <div className={styles.bannerContainer}>
      <img src={Banner} alt="loading..." />
    </div>
  );
};

export default HeaderBanner;
