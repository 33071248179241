import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Profile from "../components/Profile/Profile";

function Account() {
  document.title = "Profile - Bandhej Hub";

  return (
    <>
      <Navbar />
      <Profile />
    </>
  );
}

export default Account;
