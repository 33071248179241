import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import BhLogo from "../assets/bhlogo.png";
import { RxCross1, BiShow, BiHide } from "../assets/Icons";
import { UserContext } from "../context/userContext";
import axios from "axios";
import { USER_LOGIN } from "../components/Apis";
import Loader from "../assets/spinnerbandhej.svg";
import { toast } from "react-toastify";
const LoginModal = ({ isOpen }) => {
  const { setIsLoginOpen, setIsRegisterOpen } = useContext(UserContext);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [userData, setUserData] = useState({
    ephone: "",
    password: "",
  });

  if (!isOpen) return null;
  const CloseModal = () => {
    setIsLoginOpen(false);
    document.body.style.overflow = "auto";
  };
  const OpenRegister = () => {
    setIsRegisterOpen(true);
    setIsLoginOpen(false);
  };
  const handleInput = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    setButtonLoader(true);

    try {
      const response = await axios.post(USER_LOGIN, userData, {
        withCredentials: true,
      });
      localStorage.setItem("BandhejHub", response.data.accessToken);
      // document.cookie = `bhUserRefreshToken=${response.data.bhUserRefreshToken}; path=/; max-age=604800; secure; samesite=strict`;
      window.location.href = "/";
      setButtonLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setButtonLoader(false);
    }

    return true;
  };
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <button onClick={CloseModal} className={styles.closeButton}>
            <RxCross1 />
          </button>
          <img src={BhLogo} alt="Bandhej Hub" className={styles.logo} />
          <form method="POST" onSubmit={SubmitForm}>
            {/* Email  */}
            <div className={styles.inputGroup}>
              <label htmlFor="email">
                Email id or mobile number <span>*</span>{" "}
              </label>
              <input
                type="text"
                name="ephone"
                required
                value={userData?.ephone}
                onChange={handleInput}
              />
            </div>
            {/* Password  */}
            <div className={styles.inputGroup}>
              <label htmlFor="password">
                Password <span>*</span>
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                required
                value={userData?.password}
                onChange={handleInput}
              />
              <span
                className={styles.showPassword}
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <BiHide size={16} /> : <BiShow size={16} />}
              </span>
            </div>
            <p className={styles.forgotPassword}>Forgot password?</p>
            {buttonLoader ? (
              <img src={Loader} alt="Loading..." className={styles.loader} />
            ) : (
              <button className={styles.loginButton}>Login</button>
            )}
          </form>
          <p className={styles.registerbtn}>
            New to Bandhej Hub?{" "}
            <span onClick={OpenRegister}>Create account</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
