import React, { useContext, useState } from "react";
import styles from "./Navbar.module.css";
import {
  TfiSearch,
  TfiHeart,
  CiMenuFries,
  PiShoppingCart,
  CiUser,
  RxCross1,
  LiaShippingFastSolid,
} from "../../assets/Icons";
import { Link, useNavigate } from "react-router-dom";
import BHLogo from "../../assets/bhlogo.png";
import { UserContext } from "../../context/userContext";
import LoginModal from "../../Modals/Login";
import RegisterModal from "../../Modals/Register"; // Import RegisterModal

function Navbar() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("BandhejHub");

  const { cartData, isLoginOpen, setIsLoginOpen, isRegisterOpen } =
    useContext(UserContext);

  //  STATES
  const [stickyClass, setStickyClass] = useState("");
  const [clickMenu, setClickMenu] = useState(false);
  const [listChange, setListChange] = useState(true);
  // const sidebarRef = useRef(null); // Create a ref for the sidebar

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 40 ? setStickyClass("stickyNavCome") : setStickyClass("");
    }
  };

  const ClickMenuIcons = () => {
    if (clickMenu) document.body.style.overflow = "auto";
    else document.body.style.overflow = "hidden";
    setClickMenu(!clickMenu);
  };

  // Function to handle clicks outside the sidebar
  // const handleClickOutside = (event) => {
  //   if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //     console.log(clickMenu);
  //     if (clickMenu === false) {
  //       setClickMenu(false);
  //       document.body.style.overflow = "auto";
  //     }
  //   }
  // };

  const clickList = (value) => {
    if (value === "/") navigate("/");
    else if (value === "myorders") navigate("/myorders?status=All");
    else if (
      value === "Saree" ||
      value === "Dupatta" ||
      value === "Lehanga" ||
      value === "Dress"
    ) {
      navigate(`/${value}?page=1`);
    } else navigate(`/${value}`);

    document.body.style.overflow = "auto";
    setClickMenu(!clickMenu);
  };

  const ListChange = (value) => {
    if (value === "1") setListChange(true);
    else if (value === "2") setListChange(false);
  };

  const openLoginModal = () => {
    document.body.style.overflow = "hidden";
    setIsLoginOpen(true);
  };
  return (
    <>
      <div className={`${styles.navbarItem} ${stickyClass}`}>
        <div>
          <div className={styles.navbarMenuIcon}>
            <div>
              <i onClick={ClickMenuIcons}>
                <CiMenuFries />
              </i>
            </div>
            <div>
              <i>
                <TfiSearch />
              </i>
            </div>
          </div>

          <div
            // ref={sidebarRef} // Attach ref to the sidebar
            className={`${styles.navMenu} ${clickMenu ? styles.active : ""}`}
          >
            <div onClick={ClickMenuIcons} className={styles.navsideBarMenuList}>
              <i>
                <RxCross1 />
              </i>
            </div>

            <div
              className={`${styles.navsideBarMenuList} ${styles.btnListNavbar}`}
            >
              <button
                onClick={() => ListChange("1")}
                className={listChange === true ? styles.activebtn : ""}
              >
                Menu
              </button>
              <button
                onClick={() => ListChange("2")}
                className={listChange === false ? styles.activebtn : ""}
              >
                Categories
              </button>
            </div>
            {/* currency convert  */}
            {/* <div
              className={`${styles.navsideBarMenuList} ${styles.currencyNavbarlist}`}
            >
              <ul>
                <li className={styles.navItemCurrency}>
                  <select value={country} onChange={handleCurrencyChange}>
                    {currencyOptions.map((option) => (
                      <option key={option.country} value={option?.country}>
                        {option.flag} {option.country} ({option.value})
                      </option>
                    ))}
                  </select>
                </li>
              </ul>
            </div> */}
            {listChange ? (
              <div className={styles.menuList}>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("/")}
                >
                  All Products
                </div>
                {/* <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("Top-Trending")}
                >
                  Top Trending
                </div> */}
                {/* <div className={styles.navsideBarMenuList}>Recently added </div> */}
                {/* <div className={styles.navsideBarMenuList}>
                  <MdOutlineLocalOffer />
                  Offered Item
                </div> */}
                {/* <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("Recomanded")}
                >
                  Recommanded
                </div> */}
                {/* <div className={styles.navsideBarMenuList}>Fastival Sale</div> */}
                {/* <div className={styles.navsideBarMenuList}>Blogs</div> */}
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("myorders")}
                >
                  <LiaShippingFastSolid />
                  My Orders
                </div>
                <div className={styles.navsideBarMenuList}>
                  <TfiHeart />
                  Wishlist
                </div>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("cart")}
                >
                  <PiShoppingCart />
                  Cart
                </div>
                {accessToken ? (
                  <div
                    className={styles.navsideBarMenuList}
                    onClick={() => clickList("account")}
                  >
                    <CiUser />
                    Account
                  </div>
                ) : (
                  <div
                    className={styles.navsideBarMenuList}
                    onClick={openLoginModal}
                  >
                    <CiUser />
                    Login/Register
                  </div>
                )}
                <div
                  className={styles.navsideBarMenuList}
                  style={{ cursor: "auto" }}
                >
                  <p>
                    Need Help? <br /> +917740930250 <br />
                    bandhejhub@gmail.com
                  </p>
                </div>
                <div className={styles.navsideBarMenuList}>
                  <a
                    href="https://seller.bandhejhub.tech"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Become a Seller?
                  </a>
                </div>
              </div>
            ) : (
              <div className={styles.categoryList}>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("/")}
                >
                  All Products
                </div>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("Saree")}
                >
                  Saree
                </div>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("Dupatta")}
                >
                  Dupatta
                </div>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("Lehanga")}
                >
                  Lehanga
                </div>
                <div
                  className={styles.navsideBarMenuList}
                  onClick={() => clickList("Dress")}
                >
                  Dress
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={styles.brandName}>
          <Link to="/">
            <img src={BHLogo} alt="Loading.." />
          </Link>
        </div>

        <div className={styles.navItems}>
          <ul>
            {/* <li className={styles.navItemCurrency}>
              <select value={country} onChange={handleCurrencyChange}>
                {currencyOptions.map((option) => (
                  <option key={option.country} value={option?.country}>
                    {option.flag} {option.value}
                  </option>
                ))}
              </select>
            </li> */}
            {/* <li className={styles.navItemSearch}>
              <a href="/">
                <TfiSearch />
              </a>
            </li> */}
            <li className={styles.navItemUser}>
              {accessToken ? (
                <Link to="/account" style={{ fontSize: "25px" }}>
                  <CiUser />
                </Link>
              ) : (
                <div
                  onClick={openLoginModal}
                  style={{ fontSize: "25px", cursor: "pointer" }}
                >
                  <CiUser />
                </div>
              )}
            </li>
            <li className={styles.navItemWhitlist}>
              <a href="/">
                <TfiHeart />
              </a>
            </li>
            <li className={styles.navItemCart}>
              <Link to="/cart">
                <PiShoppingCart />
              </Link>
              <span>{cartData?.length || 0}</span>
            </li>
          </ul>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} />
      <RegisterModal isOpen={isRegisterOpen} />
    </>
  );
}

export default Navbar;
